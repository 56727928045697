import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Container, Row, CardHeader, Col, CardBody } from "reactstrap";
import "nouislider/distribute/nouislider.css";

import { convertValidDate, convertValidTime, formatDateTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ImportCSVModal from "../../../../Components/Common/ImportCSVModal";
import { Actions } from "../../../../Components/constants/common";

import { deleteProduct, listProduct, setConfigTour } from "../../../../store/actions";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { ADD_PRODUCT } from "../../../../common/data/Permission/product";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import { Tooltip } from "antd";
import QuickAddProductModal from "./Components/QuickAddProductModal";
import LogoTheme from "../../../../assets/images/logo-theme.png";

const ProductsPage = (props) => {
  const {
    router: { navigate, locations, params, t },
  } = props;
  document.title = `${t("Products List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    products,
    productsLoading,
    productsAction,
    productsLimit,
    productsSuccess,
    productsPage,
    productsTotal,
    productsMessage,
  } = useMemoizedSelector((state) => ({
    products: state.Products.products,
    productsMessage: state.Products.message,
    productsAction: state.Products.action,
    productsLimit: state.Products.limit,
    productsPage: state.Products.page,
    productsTotal: state.Products.total,
    productsLoading: state.Products.loading,
    productsSuccess: state.Products.success,
    productsError: state.Products.error,
  }));

  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));
  const [lightLogo, setLightLogo] = useState(null);

  useEffect(() => {
    setLightLogo(version?.data?.dict?.shop_info?.setting_value?.store_logo_light?.url ?? LogoTheme);
  }, [version]);

  const [product, setProduct] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [isImportCSV, setIsImportCSV] = useState(false);
  const [showQuickAdd, setShowQuickAdd] = useState(false);

  const toggleQuickAdd = () => {
    setShowQuickAdd(!showQuickAdd);
  };

  const onClickDelete = (product) => {
    setProduct(product);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (product) {
      dispatch(deleteProduct(product));
    }
  };

  const handleCloseDeleteModal = () => {
    if (!productsLoading) {
      setDeleteModal(false);
      setProduct(null);
    }
  };

  useEffect(() => {
    if (!productsLoading && productsSuccess && deleteModal && productsMessage === "Delete product success") {
      handleCloseDeleteModal();
    }
  }, [productsLoading, productsSuccess, productsMessage]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const steps = [
    {
      title: t("Upload File"),
      description: t("Put your files here."),
      target: () => ref1.current,
    },
    {
      title: t("Save"),
      description: t("Save your changes."),
      target: () => ref2.current,
    },
  ];

  useEffect(() => {
    dispatch(
      setConfigTour({
        typeTour: "product",
        steps,
      }),
    );
    return () => {
      dispatch(
        setConfigTour({
          typeTour: "product",
          steps: [],
        }),
      );
    };
  }, [dispatch]);

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={products} />
      <ImportCSVModal show={isImportCSV} onCloseClick={() => setIsImportCSV(false)} />
      <QuickAddProductModal show={showQuickAdd} toggle={toggleQuickAdd} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={handleCloseDeleteModal}
        loading={productsLoading && productsAction === Actions.DELETE}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div>
              <div className="card">
                <CardHeader className="border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">{t("Product List")}</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-external-link-fill"
                          onClick={() => {
                            setIsImportCSV(true);
                          }}
                          refTour={ref2}
                        >
                          {t("Import")}
                        </ButtonTheme>
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="quick-create-btn"
                          icon="ri-add-line text-white"
                          onClick={toggleQuickAdd}
                        >
                          {t("Quickly Add Product")}
                        </ButtonTheme>
                        <ButtonTheme
                          // keyPermission={ADD_PRODUCT}
                          type="button"
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            navigate("/products/new");
                          }}
                          refTour={ref1}
                        >
                          {t("Add Product")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"products"}
                    searchPlaceHolder={"Search product by name, sku, barcode..."}
                    listFilter={[
                      {
                        id: "source.channel_name",
                        type: "selectBox",
                        title: `${t("Source")}`,
                        dataOption: [
                          {
                            id: "system",
                            name: version?.data?.dict?.shop_info?.setting_value?.store_name || "OptiWarehouse",
                          },
                          ...(saleChannels ?? []).map((item) => {
                            return { id: item.key, name: item.name };
                          }),
                        ],
                      },
                      {
                        id: "category.id",
                        type: "selectBox",
                        title: "Category",
                        remote: true,
                        pathUrlLoad: "product/categories",
                      },
                      {
                        id: "brand.id",
                        type: "selectBox",
                        title: "Brand",
                        remote: true,
                        pathUrlLoad: "product/brands",
                      },
                    ]}
                    headers={[
                      "source",
                      "name",
                      "images",
                      "category",
                      "brand",
                      "sku",
                      "updated_at",
                      "created_at",
                      "action",
                    ]}
                    contents={products}
                    limitPage={Number(productsLimit)}
                    currentPage={Number(productsPage) + 1}
                    totalItems={productsTotal}
                    loading={productsLoading && productsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listProduct(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Product details"),
                        key: "detail-product",
                        children: [
                          {
                            title: t("Source"),
                            dataIndex: "source",
                            width: "70px",
                            key: "source",
                            render: (value, row, index) => {
                              const saleChannel = saleChannels.find((item) => item.key === row?.source?.channel_name);
                              if (saleChannel) {
                                return (
                                  <ImageCustom
                                    image={saleChannel?.logo}
                                    name={saleChannel?.name}
                                    avatarSize={"avatar-xs"}
                                    isPreview={false}
                                  />
                                );
                              } else {
                                return (
                                  <ImageCustom
                                    image={lightLogo}
                                    name={row?.source?.name}
                                    avatarSize={"avatar-xs"}
                                    isPreview={false}
                                  />
                                );
                              }
                            },
                          },
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            width: "10%",
                            key: "name",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <Tooltip title={t(row.name)}>
                                  <Link
                                    to={`/products/${row.id}`}
                                    className="d-block text-truncate link-primary fs-14 h5 mb-0"
                                  >
                                    {t(row.name)}
                                  </Link>
                                </Tooltip>
                              );
                            },
                          },
                          {
                            title: t("Image"),
                            dataIndex: "images",
                            width: "90px",
                            key: "images",
                            render: (value, row, index) => {
                              return (
                                <ImageCustom image={row.images?.[0]?.url} name={row.name} avatarSize={"avatar-xs"} />
                              );
                            },
                          },

                          {
                            title: t("Category"),
                            dataIndex: "category",
                            key: "category",
                            sortKey: "category.name",
                            sorter: true,
                            render: (value, row, index) => {
                              return <div className="d-block text-truncate">{t(value?.name) || "---"}</div>;
                            },
                          },
                          {
                            title: t("Brand"),
                            dataIndex: "brand",
                            key: "brand",
                            sortKey: "brand.name",
                            sorter: true,
                            render: (value, row, index) => {
                              return <div className="d-block text-truncate">{t(value?.name) || "---"}</div>;
                            },
                          },
                          {
                            title: "Sku",
                            dataIndex: "sku",
                            key: "sku",
                            sorter: true,
                          },

                          {
                            title: t("Updated at"),
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sorter: true,
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    { type: "view", onClick: () => navigate(`/products/${row.id}`) },
                                    {
                                      type: "edit",
                                      onClick: () => navigate(`/products/${row.id}/edit`),
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(row);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ProductsPage);
