import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ChangePassword from "./auth/changepwd/reducer";
import ResetPassword from "./auth/resetpwd/reducer";
import Permission from "./auth/permission/reducer";
//Ecommerce
import Products from "./ecommerce/product/products/reducer";
import Variants from "./ecommerce/product/variants/reducer";
import Brands from "./ecommerce/product/brands/reducer";
import Units from "./ecommerce/product/units/reducer";
import Categories from "./ecommerce/product/categories/reducer";
import Locations from "./ecommerce/inventory/locations/reducer";
import InventoryItems from "./ecommerce/inventory/inventory_items/reducer";
import PriceGroups from "./ecommerce/price/price_groups/reducer";
import Customers from "./ecommerce/customer/customers/reducer";
import CustomerGroups from "./ecommerce/customer/customer_groups/reducer";
import Suppliers from "./ecommerce/purchase_order/suppliers/reducer";
import Orders from "./ecommerce/order/orders/reducer";
import ReturnOrders from "./ecommerce/order/return_orders/reducer";
import Packages from "./ecommerce/order/packages/reducer";
import PurchaseOrders from "./ecommerce/purchase_order/purchase_orders/reducer";
import PaymentMethods from "./ecommerce/finance/payment_methods/reducer";
import TransactionVouchers from "./ecommerce/finance/vouchers/reducer";
import Accounts from "./ecommerce/finance/accounts/reducer";
import Transactions from "./ecommerce/finance/transactions/reducer";
import SaleChannels from "./ecommerce/sale_channel/sale_channels/reducer";
import InstalledChannels from "./ecommerce/sale_channel/installed_channels/reducer";
import Stores from "./ecommerce/sale_channel/stores/reducer";
import ShippingProviders from "./ecommerce/logistic/shipping_providers/reducer";
import Imports from "./ecommerce/import/imports/reducer";
import Records from "./ecommerce/import/records/reducer";
import Activities from "./ecommerce/activities/reducer";
import RewardPrograms from "./ecommerce/loyalty/reward_programs/reducer";
import LoyaltyPrograms from "./ecommerce/loyalty/loyalty_programs/reducer";
import Discounts from "./ecommerce/promotion/discounts/reducer";
import Vouchers from "./ecommerce/promotion/vouchers/reducer";
import Blogs from "./ecommerce/blog/blogs/reducer";
import BlogCategories from "./ecommerce/blog/categories/reducer";
import Shifts from "./ecommerce/pos/shifts/reducer";
import Terminals from "./ecommerce/pos/terminals/reducer";
import DraftOrders from "./ecommerce/pos/draft_orders/reducer";
import StockAdjustments from "./ecommerce/inventory/stock_adjustments/reducer";
import StockRelocates from "./ecommerce/inventory/stock_relocates/reducer";
import Roles from "./ecommerce/settings/roles/reducer";
import LoyaltyApp from "./ecommerce/loyalty_app/reducer";
import Notifications from "./ecommerce/notification/notifications/reducer";

// Dashboard Ecommerce
import DashboardEcommerce from "./dashboardEcommerce/reducer";
import ShopInfo from "./shop_info/reducer";
import TemplatePrint from "./template_print/reducer";
import Tour from "./tour/reducer";
import Version from "./version/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  ChangePassword,
  ResetPassword,
  Permission,

  Products,
  Variants,
  Brands,
  Units,
  Categories,
  PriceGroups,
  Locations,
  InventoryItems,
  Orders,
  ReturnOrders,
  Customers,
  Suppliers,
  Packages,
  PurchaseOrders,
  PaymentMethods,
  TransactionVouchers,
  Accounts,
  Transactions,
  SaleChannels,
  InstalledChannels,
  Stores,
  ShippingProviders,
  Imports,
  Records,
  Activities,
  DashboardEcommerce,
  CustomerGroups,
  RewardPrograms,
  LoyaltyPrograms,
  Discounts,
  Vouchers,
  ShopInfo,
  TemplatePrint,
  Tour,
  Blogs,
  BlogCategories,
  Shifts,
  Terminals,
  DraftOrders,
  StockAdjustments,
  StockRelocates,
  Roles,
  Version,
  LoyaltyApp,
  Notifications,
});

export default rootReducer;
