import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { updateDynamicSettings } from "../../../../../helpers/service_helper";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import { deepCopy, isValidJson, snakeToCapitalizedWords } from "../../../../../helpers/format_helper";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import SearchInput from "../../../../../Components/Common/SearchInput";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import ScrollToError from "../../../../../Components/Common/ScrollToError";

const DynamicSettingsForm = ({ id, initialDynamicSettings }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const processedValues = Object.entries(values).reduce((acc, [key, value]) => {
        if (key === "location_mapping") {
          acc[key] = {
            mapping: value.mapping,
          };
        } else if (typeof initialDynamicSettings[key] === "object" && initialDynamicSettings[key] !== null) {
          acc[key] = JSON.parse(value);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
      await updateDynamicSettings(id, { dynamic_settings: processedValues });
      toast.success("Dynamic settings updated successfully!");
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const initialFormValues = Object.entries(initialDynamicSettings).reduce((acc, [key, value]) => {
    if (key === "location_mapping") {
      acc[key] = { mapping: value.mapping };
    } else if (typeof value === "object" && value !== null) {
      acc[key] = JSON.stringify(value, null, 2);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

  const validationSchema = Yup.object().shape(
    Object.entries(initialDynamicSettings).reduce((acc, [key, value]) => {
      if (key === "location_mapping") {
        acc[key] = Yup.object().shape({
          mapping: Yup.object().test("is-valid-mapping", t("Invalid mapping"), function (value) {
            return Object.values(value).every((v) => typeof v === "string" && v.trim() !== "");
          }),
        });
      } else if (typeof value === "object" && value !== null) {
        acc[key] = Yup.string().test(
          "is-valid-json",
          `${t("Invalid JSON for")} ${t(snakeToCapitalizedWords(key))}`,
          function (value) {
            if (!value) return true; // Allow empty values
            try {
              JSON.parse(value);
              return true;
            } catch (e) {
              return false;
            }
          },
        );
      } else {
        acc[key] = Yup.string().required(`${t(snakeToCapitalizedWords(key))} ${t("is required")}`);
      }
      return acc;
    }, {}),
  );

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue }) => (
        <Form>
          <ScrollToError />
          {Object.entries(initialDynamicSettings).map(([key, value]) => {
            if (key === "location_mapping") {
              return (
                <div key={key} className="mb-3">
                  <Label className="form-label">{snakeToCapitalizedWords(key)}</Label>
                  <LocationMappingForm
                    location_mapping={initialDynamicSettings?.location_mapping}
                    onChangeValue={(data) => {
                      setFieldValue(`${key}.mapping`, data);
                    }}
                  />
                </div>
              );
            } else if (typeof value === "object" && value !== null) {
              return (
                <InputTheme
                  key={key}
                  type="textarea"
                  name={key}
                  label={snakeToCapitalizedWords(key)}
                  placeholder={`Enter JSON for ${key}`}
                  rows={5}
                />
              );
            } else {
              return (
                <InputTheme
                  key={key}
                  type="text"
                  name={key}
                  label={snakeToCapitalizedWords(key)}
                  placeholder={`Enter value for ${key}`}
                />
              );
            }
          })}
          <div className="hstack gap-2 justify-content-end mt-3">
            <ButtonTheme type="submit" className="btn btn-outline-primary" loading={loading} disabled={loading}>
              Save Dynamic Settings
            </ButtonTheme>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const LocationMappingForm = ({ location_mapping, onChangeValue }) => {
  const { t } = useTranslation();
  const [mappings, setMappings] = useState({});
  const [externalLocations, setExternalLocations] = useState([]);
  const [masterLocations, setMasterLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [notification, setNotification] = useState(null);

  const handleQuickMap = () => {
    const newMappings = deepCopy(mappings);
    externalLocations.forEach((external) => {
      if (!newMappings[external.id]) {
        const match = masterLocations.find((master) => master.name.toLowerCase().includes(external.name.toLowerCase()));
        if (match) {
          newMappings[external.id] = match.id;
        }
      }
    });
    onChangeValue(newMappings);
    setNotification({ type: "success", message: "Quick mapping applied!" });
    setTimeout(() => setNotification(null), 3000);
  };
  const handleMapping = (externalId, masterId) => {
    const newMappings = deepCopy(mappings);
    if (masterId) {
      newMappings[externalId] = masterId;
    } else {
      delete newMappings[externalId];
    }
    onChangeValue(newMappings);
  };
  const filteredExternalLocations = useMemo(() => {
    return externalLocations.filter(
      (location) =>
        location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.address.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [externalLocations, searchTerm]);
  useEffect(() => {
    if (location_mapping) {
      setExternalLocations(location_mapping.external || []);
      setMasterLocations(location_mapping.master || []);
      setMappings(location_mapping.mapping || {});
    }
  }, [location_mapping]);
  return (
    <Fragment>
      <Row className="mb-4">
        <Col lg={12} className="d-flex gap-2">
          <div className="flex-grow-1">
            <SearchInput
              onChangeValue={(value) => {
                setSearchTerm(value);
              }}
              searchValue={searchTerm}
              onDeleteSearchValue={() => setSearchTerm("")}
              placeholder={"Search branches..."}
            />
          </div>
          <ButtonTheme onClick={handleQuickMap} className="btn btn-outline-primary">
            <i className="ri-flashlight-line me-2" />
            Quick Map
          </ButtonTheme>
        </Col>
      </Row>
      {notification && (
        <Alert color={notification.type === "success" ? "success" : "danger"} className="mb-4">
          {notification.message}
        </Alert>
      )}
      <SimpleBar style={{ maxHeight: "300px" }}>
        <div className="px-4">
          {filteredExternalLocations.map((location) => (
            <Card className="mb-4" key={location.id}>
              <CardBody className="shadow-lg">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h5 className="mb-0">{location.name}</h5>
                  <i className="ri-map-2-line text-gray-500 fs-20" />
                </div>
                <p className="small text-muted mb-2">{location.address}</p>
                <p className="small text-muted mb-3">
                  {location.cityName}, {location.districtName}, {location.wardName}
                </p>
                <SelectTheme
                  placeholder="Select branch"
                  isForm={false}
                  options={masterLocations}
                  value={mappings[location.id] || ""}
                  onChange={(value) => {
                    handleMapping(location.id, value?.value);
                  }}
                />
              </CardBody>
            </Card>
          ))}
        </div>
      </SimpleBar>
    </Fragment>
  );
};

export default DynamicSettingsForm;
