import React from "react";
import { Navigate } from "react-router-dom";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";

import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import VerifyConfirmationCodePage from "../pages/Authentication/VerifyConfirmationCode";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";

// User Profile
import SettingsProfile from "../pages/Profile/Settings";

import ProductsPage from "../pages/Ecommerce/Product/Products";
import VariantsPage from "../pages/Ecommerce/Product/Variants";
import ProductDetailsPage from "../pages/Ecommerce/Product/Products/ProductDetailsPage";
import BrandsPage from "../pages/Ecommerce/Product/Brands";
import CategoriesPage from "../pages/Ecommerce/Product/Categories";
import LocationsPage from "../pages/Ecommerce/Inventory/Locations";
import InventoryItemPage from "../pages/Ecommerce/Inventory/InventoryItem";
import CustomerListPage from "../pages/Ecommerce/Customer/Customers";
import CustomerGroupPage from "../pages/Ecommerce/Customer/CustomerGroups";
import SuppliersPage from "../pages/Ecommerce/PurchaseOrders/Suppliers";
import OrdersDetailsPage from "../pages/Ecommerce/Orders/OrdersDetailsPage";
import Orders from "../pages/Ecommerce/Orders";
import POSPage from "../pages/POS";
import PurchaseOrders from "../pages/Ecommerce/PurchaseOrders";
import PurchaseOrdersDetailsPage from "../pages/Ecommerce/PurchaseOrders/PurchaseOrdersDetailsPage";
import AccountsPage from "../pages/Ecommerce/Finance/Accounts";
import PaymentMethodsPage from "../pages/Ecommerce/Finance/PaymentMethods";
import TransactionsPage from "../pages/Ecommerce/Finance/Transactions";
import TransactionVouchers from "../pages/Ecommerce/Finance/Transactions/TransactionVouchers";
import ProcessOrderPage from "../pages/Ecommerce/Orders/ProcessOrderPage";
import StorePage from "../pages/Ecommerce/SaleChannel/StorePage";
import SaleChannelPage from "../pages/Ecommerce/SaleChannel/SaleChannelPage";
import ShippingProviderPage from "../pages/Ecommerce/Logistic/ShippingProvider";
import AppStoreDetail from "../pages/Ecommerce/SaleChannel/StorePage/AppStoreDetail";
import DetailConfigApp from "../pages/Ecommerce/SaleChannel/StorePage/DetailConfigApp";
import PackageList from "../pages/Ecommerce/Orders/PackagePage";
import PackageDetail from "../pages/Ecommerce/Orders/PackagePage/PackageDetail";
import FormPrint from "../pages/Settings/Page/FormPrint";
import ImportPage from "../pages/Ecommerce/Import/Imports";
import RecordPage from "../pages/Ecommerce/Import/Records";
import RecordDetails from "../pages/Ecommerce/Import/Records/RecordDetails";
import DashboardEcommerce from "../pages/Ecommerce/DashboardEcommerce";
import LoyaltyProgram from "../pages/Ecommerce/Loyalty/LoyaltyProgram";
import RewardProgram from "../pages/Ecommerce/Loyalty/RewardProgram";
import RewardProgramDetails from "../pages/Ecommerce/Loyalty/RewardProgram/RewardProgramDetails";
import LoyaltyProgramDetails from "../pages/Ecommerce/Loyalty/LoyaltyProgram/LoyaltyProgramDetails";

import CustomerDashboard from "../pages/Ecommerce/Customer/CustomerDashboard";

//settings
import Settings from "../pages/Settings";
import ShopInfo from "../pages/Settings/Page/ShopInfo";
import ActivitiesPage from "../pages/Settings/Page/Activities";
import PriceGroupsPage from "../pages/Settings/Page/PriceGroups";
import UnitsPage from "../pages/Settings/Page/Units";

import QRCodeDetails from "../pages/QR/QRCodeDetails";
import QRCodePage from "../pages/QR";
import Discounts from "../pages/Ecommerce/Promotion/Discounts";
import EditAddDiscountPage from "../pages/Ecommerce/Promotion/Discounts/EditAddDiscountPage";
import DiscountDetailsPage from "../pages/Ecommerce/Promotion/Discounts/DiscountDetailsPage";

import VouchersPage from "../pages/Ecommerce/Promotion/Vouchers";
import CreateVoucher from "../pages/Ecommerce/Promotion/Vouchers/CreateVoucher";
import DetailVoucherCode from "../pages/Ecommerce/Promotion/Vouchers/DetailVoucherCode";
import DetailVoucher from "../pages/Ecommerce/Promotion/Vouchers/DetailVoucher";
import Accounts from "../pages/Settings/Page/Accounts";
import DetailAccount from "../pages/Settings/Page/Accounts/DetailAccount";
import Roles from "../pages/Settings/Page/Accounts/Roles";
import DetailRole from "../pages/Settings/Page/Accounts/DetailRole";
import ColorsPage from "../pages/Settings/Page/Colors";
import ProductReportDetails from "../pages/Ecommerce/Reports/ProductReport/ProductReportDetails";
import ProductReport from "../pages/Ecommerce/Reports/ProductReport";
import BlogCategoriesPage from "../pages/Ecommerce/Website/Categories";
import BlogsPage from "../pages/Ecommerce/Website/Blogs";
import BlogDetailsPage from "../pages/Ecommerce/Website/Blogs/BlogDetailsPage";
import PairDevice from "../pages/POS/PairDevice";
import PreviewBlog from "../pages/Ecommerce/Website/Blogs/PreviewBlog";
import ShiftsPage from "../pages/Ecommerce/POS/Shifts";
import TerminalsPage from "../pages/Ecommerce/POS/Terminals";
import OrderPayQR from "../pages/POS/OrderPayQR";
import ReturnOrders from "../pages/Ecommerce/Orders/ReturnOrders";
import ReturnOrderDetailsPage from "../pages/Ecommerce/Orders/ReturnOrders/ReturnOrderDetailsPage";
import StockAdjustment from "../pages/Ecommerce/Inventory/StockAdjustment";
import StockAdjustmentDetailsPage from "../pages/Ecommerce/Inventory/StockAdjustment/StockAdjustmentDetailsPage";
import StockRelocate from "../pages/Ecommerce/Inventory/StockRelocate";
import StockRelocateDetailsPage from "../pages/Ecommerce/Inventory/StockRelocate/StockRelocateDetailsPage";
import ShiftDetailsPage from "../pages/Ecommerce/POS/Shifts/ShiftDetailsPage";
import ViewProductPage from "../pages/Ecommerce/Product/Products/ViewProductPage";
import EditVariantPage from "../pages/Ecommerce/Product/Products/EditVariantPage";
import CustomerGroupDetails from "../pages/Ecommerce/Customer/CustomerGroups/CustomerGroupDetails";
import CustomerDetails from "../pages/Ecommerce/Customer/Customers/CustomerDetails";
import LoyaltyAppPage from "../pages/Ecommerce/LoyaltyApp/LoyaltyApp";
import NewChannelPage from "../pages/Ecommerce/SaleChannel/SaleChannelPage/NewChannelPage";
import ConfigConnectionPage from "../pages/Ecommerce/SaleChannel/SaleChannelPage/ConfigConnectionPage";
import Notifications from "../pages/Ecommerce/Notification/Notifications";
import ImportDetails from "../pages/Ecommerce/Import/Imports/ImportDetails";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },

  // User Profile
  // { path: "/profile", component: <UserProfile /> },
  { path: "/profile-settings", component: <SettingsProfile /> },

  // Products
  { path: "/products", component: <ProductsPage /> },
  { path: "/products/new", component: <ProductDetailsPage /> },
  { path: "/products/:id", component: <ViewProductPage /> },
  { path: "/products/:id/edit", component: <ProductDetailsPage /> },

  { path: "/variants", component: <VariantsPage /> },
  { path: "/variants/:id", component: <ViewProductPage /> },
  { path: "/variants/:id/edit", component: <EditVariantPage /> },

  { path: "/brands", component: <BrandsPage /> },

  { path: "/settings/units", component: <UnitsPage /> },

  { path: "/categories", component: <CategoriesPage /> },

  // Orders
  { path: "/orders", component: <Orders /> },
  { path: "/orders/new", component: <OrdersDetailsPage /> },
  { path: "/orders/:id", component: <OrdersDetailsPage /> },
  { path: "/orders/:id/edit", component: <OrdersDetailsPage /> },

  // Orders
  { path: "/return-orders", component: <ReturnOrders /> },
  { path: "/return-orders/:id", component: <ReturnOrderDetailsPage /> },

  { path: "/order-process", component: <ProcessOrderPage /> },
  { path: "/packages", component: <PackageList /> },
  { path: "/packages/:id", component: <PackageDetail /> },

  // Locations
  { path: "/locations", component: <LocationsPage /> },
  { path: "/inventory-items", component: <InventoryItemPage /> },

  { path: "/stock-adjustments", component: <StockAdjustment /> },
  { path: "/stock-adjustments/new", component: <StockAdjustmentDetailsPage /> },
  { path: "/stock-adjustments/:id", component: <StockAdjustmentDetailsPage /> },

  { path: "/stock-relocates", component: <StockRelocate /> },
  { path: "/stock-relocates/:id", component: <StockRelocateDetailsPage /> },
  { path: "/stock-relocates/new", component: <StockRelocateDetailsPage /> },

  // Price Groups

  { path: "/discounts", component: <Discounts /> },
  { path: "/discounts/new", component: <EditAddDiscountPage /> },
  { path: "/discounts/:id/edit", component: <EditAddDiscountPage /> },
  { path: "/discounts/details/:id", component: <DiscountDetailsPage /> },

  ///
  { path: "/vouchers", component: <VouchersPage /> },
  { path: "/vouchers/create", component: <CreateVoucher /> },
  { path: "/vouchers/:id/edit", component: <CreateVoucher /> },
  { path: "/vouchers/details/:idVoucher", component: <DetailVoucher /> },
  { path: "/voucher-code/:id_voucher", component: <DetailVoucherCode /> },

  // Sale Channels
  { path: "/stores", component: <StorePage /> },
  { path: "/stores/:id", component: <AppStoreDetail /> },
  { path: "/stores/settings/:id", component: <DetailConfigApp /> },

  { path: "/channels", component: <SaleChannelPage /> },
  { path: "/channels/:connection_type/new", component: <NewChannelPage /> },
  { path: "/channels/connections/:id", component: <ConfigConnectionPage /> },

  { path: "/shipping-providers", component: <ShippingProviderPage /> },

  { path: "/imports", component: <ImportPage /> },
  { path: "/imports/:id", component: <ImportDetails /> },
  { path: "/records", component: <RecordPage /> },
  { path: "/records/:id", component: <RecordDetails /> },

  // Purchase Orders
  { path: "/purchase-orders", component: <PurchaseOrders /> },
  { path: "/purchase-orders/new", component: <PurchaseOrdersDetailsPage /> },
  { path: "/purchase-orders/:id", component: <PurchaseOrdersDetailsPage /> },
  { path: "/purchase-orders/:id/edit", component: <PurchaseOrdersDetailsPage /> },
  { path: "/suppliers", component: <SuppliersPage /> },

  // Customers
  { path: "/customer-dashboard", component: <CustomerDashboard /> },
  { path: "/customers", component: <CustomerListPage /> },
  { path: "/customers/:id", component: <CustomerDetails /> },
  { path: "/customers/:new", component: <CustomerDetails /> },
  { path: "/customer-groups", component: <CustomerGroupPage /> },
  { path: "/customer-groups/new", component: <CustomerGroupDetails /> },
  { path: "/customer-groups/:id", component: <CustomerGroupDetails /> },

  // Loyalty
  { path: "/loyalty-programs", component: <LoyaltyProgram /> },
  { path: "/loyalty-programs/:id", component: <LoyaltyProgramDetails /> },
  { path: "/loyalty-programs/new", component: <LoyaltyProgramDetails /> },
  { path: "/reward-programs", component: <RewardProgram /> },
  { path: "/reward-programs/:id", component: <RewardProgramDetails /> },
  { path: "/reward-programs/new", component: <RewardProgramDetails /> },

  { path: "/accounts", component: <AccountsPage /> },
  { path: "/payment-methods", component: <PaymentMethodsPage /> },
  { path: "/transactions", component: <TransactionsPage /> },
  { path: "/transaction/vouchers", component: <TransactionVouchers /> },

  { path: "/settings", component: <Settings /> },
  { path: "/settings/activities", component: <ActivitiesPage /> },
  { path: "/settings/prints", component: <FormPrint /> },
  { path: "/settings/shop-info", component: <ShopInfo /> },
  { path: "/settings/price-groups", component: <PriceGroupsPage /> },

  { path: "/settings/accounts", component: <Accounts /> },
  { path: "/settings/accounts/create", component: <DetailAccount /> },
  { path: "/settings/accounts/:username", component: <DetailAccount /> },

  { path: "/settings/accounts/role", component: <Roles /> },
  { path: "/settings/accounts/role/:id", component: <DetailRole /> },
  { path: "/settings/colors", component: <ColorsPage /> },

  { path: "/report/products/:id", component: <ProductReportDetails /> },
  { path: "/report/products", component: <ProductReport /> },

  { path: "/blogs", component: <BlogsPage /> },
  { path: "/blogs/:id", component: <PreviewBlog /> },
  { path: "/blogs/new", component: <BlogDetailsPage /> },
  { path: "/blogs/:id/edit", component: <BlogDetailsPage /> },

  { path: "/blog-categories", component: <BlogCategoriesPage /> },

  { path: "/loyalty-app", component: <LoyaltyAppPage /> },

  { path: "/notifications", component: <Notifications /> },

  { path: "/shifts", component: <ShiftsPage /> },
  { path: "/shifts/:id", component: <ShiftDetailsPage /> },

  { path: "/terminals", component: <TerminalsPage /> },

  { path: "/pos", component: <POSPage /> },

  { path: "/order-qr/:id", component: <OrderPayQR /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/dashboard",
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Alt404 /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
  { path: "/verify-confirmation-code", component: <VerifyConfirmationCodePage /> },
  { path: "/reset-password", component: <ResetPasswordPage /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/qr", component: <QRCodePage /> },
  { path: "/qr/:id", component: <QRCodeDetails /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

  { path: "/pos/pair-device", component: <PairDevice /> },
];

export { authProtectedRoutes, publicRoutes };
